import React from 'react';
import PropTypes from 'prop-types';
import { domAnimation, LazyMotion, m } from 'framer-motion';
import { Icon } from '@chakra-ui/react';

const topHamburger = {
  resting: {
    d: 'M10 40 L90 40 L90 60 L10 60 Z',
    rotate: 0,
    y: -30,
  },
  open: {
    d: 'M10 40 L90 40 L90 60 L10 60 Z',
    rotate: 45,
    y: 0,
  },
};

const middleHamburger = {
  resting: {
    x: '0',
    opacity: 1,
  },
  open: {
    x: '-100%',
    opacity: 0,
  },
};

const bottomHamburger = {
  resting: {
    d: 'M10 40 L90 40 L90 60 L10 60 Z',
    rotate: 0,
    y: 30,
  },
  open: {
    d: 'M10 40 L90 40 L90 60 L10 60 Z',
    rotate: -45,
    y: 0,
  },
};

function MenuIcon({ isOpen }) {
  return (
    <Icon viewBox="0 0 100 100" fill="currentColor">
      <LazyMotion features={domAnimation} strict>
        <m.path
          animate={isOpen ? 'open' : 'resting'}
          d="M10 10 L90 10 L90 30 L10 30 Z"
          initial="resting"
          style={{ originX: '50%', originY: '50%' }}
          variants={topHamburger}
        />
        <m.path
          animate={isOpen ? 'open' : 'resting'}
          d="M10 40 L90 40 L90 60 L10 60 Z"
          initial="resting"
          variants={middleHamburger}
        />
        <m.path
          animate={isOpen ? 'open' : 'resting'}
          d="M10 70 L90 70 L90 90 L10 90 Z"
          initial="resting"
          style={{ originX: '50%', originY: '50%' }}
          variants={bottomHamburger}
        />
      </LazyMotion>
    </Icon>
  );
}

MenuIcon.propTypes = {
  isOpen: PropTypes.bool.isRequired,
};

export default MenuIcon;
