import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Link as GatsbyLink } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import {
  Flex, IconButton, ListItem, Link, UnorderedList, useBreakpointValue, Box, useBoolean,
} from '@chakra-ui/react';
import {
  domAnimation, LazyMotion, m,
} from 'framer-motion';
import MenuIcon from './MenuIcon';
import data from '../../data.json';

const AnimatedList = m(UnorderedList);
const AnimatedListItem = m(ListItem);
const menuAnimation = {
  hidden: {
    height: 0,
    opacity: 0,
    transition: {
      duration: 0.2,
      staggerChildren: 0.05,
      staggerDirection: -1,
      when: 'afterChildren',
    },
  },
  visible: {
    opacity: 1,
    transition: {
      duration: 0.2,
      staggerChildren: 0.05,
      when: 'beforeChildren',
    },
  },
};
const itemAnimation = {
  hidden: {
    x: '-100%',
    opacity: 0,
    transition: {
      duration: 0.2,
    },
  },
  visible: {
    x: 0,
    opacity: 1,
    transition: {
      duration: 0.2,
    },
  },
};

const Navbar = ({ location }) => {
  const isLargeWindow = useBreakpointValue({ base: false, md: true });
  const [buttonPressed, handleButtonPress] = useBoolean(true);
  const [menuOpen, setMenuOpen] = useState(true);

  useEffect(() => {
    if (!isLargeWindow && !buttonPressed) setMenuOpen(false);
    else setMenuOpen(true);
  }, [isLargeWindow, buttonPressed]);

  return (
    <Flex
      align="stretch"
      as="header"
      bg="primary"
      boxShadow="md"
      flexFlow="row wrap"
      justify="space-between"
      pos="sticky"
      top={0}
    >
      <StaticImage
        alt="OCI"
        src="../../assets/logo.svg"
        height={64}
        width={128}
      />

      {!isLargeWindow
        && (
        <IconButton
          aria-label={menuOpen ? 'Fechar Menu' : 'Abrir Menu'}
          colorScheme="whiteAlpha"
          fontSize="20px"
          display={{ base: 'flex', md: 'none' }}
          onClick={handleButtonPress.toggle}
          icon={<MenuIcon isOpen={menuOpen} />}
          mx={3}
          my="auto"
        />
        )}
      <Box
        as="nav"
        flexBasis={{ base: '100%', md: 'auto' }}
      >
        <LazyMotion features={domAnimation} strict>
          <AnimatedList
            animate={menuOpen ? 'visible' : 'hidden'}
            custom={isLargeWindow}
            display="flex"
            flexDir={{ base: 'column', md: 'row' }}
            h={{ base: 'auto', md: '100%' }}
            initial={false}
            key="menu"
            listStyleType="none"
            marginStart="0"
            opacity="1"
            variants={menuAnimation}
          >
            {Object.entries(data.navlinks).map(([nome, url]) => (
              <AnimatedListItem
                alignItems={{ base: 'left', md: 'center' }}
                bg={url === location ? 'white' : 'primary'}
                color={url === location ? 'primary' : 'white'}
                display="flex"
                initial={false}
                justifyContent={{ base: 'flex-start', md: 'center' }}
                key={url}
                opacity="1"
                overflow="hidden"
                p={2}
                paddingStart={{ base: 5, md: 2 }}
                variants={itemAnimation}
                x="0"
                _last={{
                  marginEnd: { base: 0, md: 3 },
                  marginBottom: { base: 3, md: 0 },
                }}
              >
                <Link
                  aria-current={url === location ? 'page' : null}
                  as={GatsbyLink}
                  fontWeight="bold"
                  to={url}
                  w="100%"
                >
                  {nome}
                </Link>
              </AnimatedListItem>
            ))}
          </AnimatedList>
        </LazyMotion>
      </Box>
    </Flex>
  );
};

Navbar.propTypes = {
  location: PropTypes.string.isRequired,
};

export default Navbar;
