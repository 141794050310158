import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import Navbar from './Navbar';

export default function Layout({ children, location }) {
  return (
    <>
      <Helmet
        htmlAttributes={{
          lang: 'pt-BR',
        }}
      >
        <title>Olimpiada Cearense de Informática</title>
        <meta
          name="description"
          content="A Olimpíada Cearense de Informática é um evento organizado
          pelos grupos do PET (Programa de Educação Tutorial) Computação e
          Teleinformática da Universidade Federal do Ceará (UFC), com objetivo
          de estimular o interesse pela Computação e pela Ciência em geral."
        />
      </Helmet>
      <Navbar location={location} />
      {children}
    </>
  );
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  location: PropTypes.string.isRequired,
};
